import React from 'react';
import axios from 'axios';
import { Table, Dropdown, Icon, Menu, Modal, message, Button } from 'antd';
import * as Style from '../../../../orders/orderStyle'
import CustomerOrderDetails from '../../../../orders/customer_order_details/customer_order_details'
import {history} from '../../../../../store/history'
import multiwalletServices from '../../../multiwalletServices';
import moment from 'moment';

const TransactionTable = (props) => {

    const { data, isLoading, length, selected } = props;
    const [loading, setLoading] = React.useState(false);

    const [detailsModalVisibility, setDetailsModalVisibility] = React.useState(false)
    const [selectedOrderDetails, setSelectedOrderDetails] = React.useState(null)

    const [cancelModalVisibility, setCancelModalVisibility] = React.useState(false)
    const [imageModal, setImageModal] = React.useState(false)
    const [idToBeCancel, setIdToBeCancel] = React.useState("")
    const [imageUrl, setImageUrl] = React.useState("")
    const testMode = (history.location.pathname.indexOf('/test') > -1);

    async function simulatePayment(id) {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }
      
        let response = await axios.get(process.env.REACT_APP_API_URL + `/api/sandbox/simulate/?id=${id}`, yourConfig);

        if(response.data.status == "success"){
            message.success('Successfully Paid')
            window.location.reload()
        }
    }



    const menu = (details) => (
        <Menu style={{ width: '214px' }}>
            <Menu.Item key="0" onClick={() => history.push(testMode ?`/test/payment/${details.uid}` : `/payment/${details.uid}`)} style={Style.menuStyle} hidden={selected == 'Cancelled' || selected == 'Expired'}>
                View payment receipt
            </Menu.Item>  
            <Menu.Item key="1" onClick={() => {setSelectedOrderDetails(details); setDetailsModalVisibility(true)}} style={Style.menuStyle}>
                View payment details
            </Menu.Item>    
            <Menu.Item key="2" onClick={()=> {setIdToBeCancel(details.id); setCancelModalVisibility(true)}} style={Style.menuStyle} hidden={selected != "Pending"}>
                Cancel payment
            </Menu.Item>
            {
                details.channel == 'manual_add' &&
                <Menu.Item key="3" onClick={()=>{setImageUrl(details.image_proof); setImageModal(true)}} style={Style.menuStyle}>
                    View Attachment
                </Menu.Item>
            }
            {
                selected === "Pending" &&
                <Menu.Item key="4" onClick={() => simulatePayment(details.id)} style={Style.menuStyle} hidden={!testMode}>
                    Simulate Payment
                </Menu.Item>
            }
       
        </Menu>
    );

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const cancelPayment = async() => {
        let response = await multiwalletServices.cancelPayment(testMode, idToBeCancel)
        if(response.status == 'success'){
            message.success("Successfully cancelled")
        }
        else{
            message.error("Failed to cancel payment")
        }
        setCancelModalVisibility(false)
    }

    const paid_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 100,
            fixed: 'left',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            fixed: 'left',
            render: (text, record) => {
                return <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150, overflow: 'hidden' }}>
                        <a style={Style.codeStyle}>{record.code} </a>
                        <div style={Style.descSyle}>{record.description}</div>
                    </div>
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 100,
            render: (text, record) => {
                return <div style={Style.channelDivStyle}>
                <div style={Style.codeStyle}>{text}</div>
                <div style={Style.descSyle}>{record.terminal}</div>
            </div>
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Date Paid',
            dataIndex: 'date_paid',
            width: 160,
            render: text => {
                return <div>
                <div style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</div>
                </div>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 120,
            render: (text) => {
                return <span style={Style.dataStyle}>₱{parseFloat(text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
            }
        },
        {
            title: 'Receivable',
            dataIndex: 'seller_net',
            width: 120,
            render: (text, record) => {
                return <span style={Style.dataStyle}>₱{parseFloat(record.channel_str == 'Manual bank transfer' ? record.amount : text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
            }
        },
        {
            title: 'Action',
            width: 80,
            fixed: 'right',
            render: (text, record) => (
                <span>
                <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                    <Icon type="ellipsis" style={{fontSize: '24px'}} />
                </Dropdown>
                </span>
            ),
        }
    
    ];
    const pending_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 100,
            fixed: 'left',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            fixed: 'left',
            render: (text, record) => {
                return (
                    <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150, overflow: 'hidden' }}>
                        <a style={Style.codeStyle }>{record.code} </a>
                        <div style={Style.descSyle}>{record.description}</div>
                    </div>
                )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 100,
            render: (text, record) => {
                return (
                    <div style={Style.channelDivStyle}>
                        <div style={Style.codeStyle}>{text}</div>
                        <div style={Style.descSyle}>{record.terminal}</div>
                    </div>
                )
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Date Expires',
            dataIndex: 'expire_at',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 120,
            render: text => {
                return <span style={Style.dataStyle}>₱{parseFloat(text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
            }
        },
        {
            title: 'Action',
            width: 80,
            fixed: 'right',
            render: (text, record) => (
                <span>
                <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                    <Icon type="ellipsis" style={{fontSize: '24px'}}/>
                </Dropdown>
                </span>
            ),
        },
        
    ];
    const cancelled_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 100,
            fixed: 'left',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            fixed: 'left',
            render: (text, record) => {
              return (
                  <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150, overflow: 'hidden' }}>
                      <a style={Style.codeStyle}>{record.code} </a>
                      <div style={Style.descSyle}>{record.description}</div>
                  </div>
              )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 100,
            render: (text, record) => {
                return (
                  <div style={Style.channelDivStyle}>
                      <div style={Style.codeStyle}>{text}</div>
                      <div style={Style.descSyle}>{record.terminal}</div>
                  </div>
              )
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Date Cancelled',
            dataIndex: 'cancelled_at',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        // {
        //     title: 'Reason',
        //     dataIndex: 'remarks',
        //     width: 150,
        //     render: text => {
        //         return <span style={Style.dataStyle}>{text}</span>
        //     }
        // },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 120,
            render: text => {
                return <span style={Style.dataStyle}>₱{parseFloat(text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
            }
        },
        {
          title: 'Action',
          width: 80,
          fixed: 'right',
          render: (text, record) => (
            <span>
              <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                <Icon type="ellipsis" style={{fontSize: '24px'}}/>
              </Dropdown>
            </span>
          ),
        },
      ];
    
    const expired_columns = [
        {
            title: 'Trans ID',
            dataIndex: 'id',
            width: 100,
            fixed: 'left',
            render: (text, record) => {
                return text
            }
        },
        {
            title: 'Reference no.',
            dataIndex: 'code',
            width: 150,
            fixed: 'left',
            render: (text, record) => {
              return (
                  <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: 150, overflow: 'hidden' }}>
                      <a style={Style.codeStyle}>{record.code} </a>
                      <div style={Style.descSyle}>{record.description}</div>
                  </div>
              )
            }
        },
        {
            title: 'Channel',
            dataIndex: 'channel_str',
            width: 100,
            render: (text, record) => {
                return (
                  <div style={Style.channelDivStyle}>
                      <div style={Style.codeStyle}>{text}</div>
                      <div style={Style.descSyle}>{record.terminal}</div>
                  </div>
              )
            }
        },
        {
            title: 'Date Generated',
            dataIndex: 'timestamp',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Date Expired',
            dataIndex: 'expire_at',
            width: 160,
            render: text => {
                return <span style={Style.dataStyle}>{moment(text).format('MMM D, YYYY h:mm A')}</span>
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            width: 120,
            render: text => {
                return <span style={Style.dataStyle}>₱{parseFloat(text).toLocaleString("en-US", {minimumFractionDigits: 2})}</span>
            }
        },
        {
            title: 'Action',
            width: 80,
            fixed: 'right',
            render: (text, record) => (
                <span>
                <Dropdown overlay={() => menu(record)} placement="bottomLeft" trigger={['click']} >
                    <Icon type="ellipsis" style={{fontSize: '24px'}}/>
                </Dropdown>
                </span>
            ),
        },
    ];

    const setOfTableColumns = (selected) => {
        switch (selected) {
          case 'Paid':
            return paid_columns;
          case 'Pending':
            return pending_columns;
          case 'Cancelled':
            return cancelled_columns;
          case 'Expired':
            return expired_columns;
          default:
            return paid_columns;
        }
    }

    return (
        <div>
            <Table
                style={{width: '70vw'}}
                scroll={{x: 1100 }}
                rowClassName="table-row-light"
                columns={setOfTableColumns(selected)}
                dataSource={data ? data : []}
                pagination={{ pageSize: 20 }}
                loading={isLoading || loading}
                pagination={false}
            />
            <Modal
                visible={detailsModalVisibility}
                footer={null}
                width="478px"
                onCancel={()=>setDetailsModalVisibility(false)}
                style={{top: '30px'}}
            >
                <CustomerOrderDetails details={selectedOrderDetails}/>
            </Modal>
            <Modal
                centered
                width={360}
                visible={cancelModalVisibility}
                onCancel={() => setCancelModalVisibility(false)}
                footer={null}>
                    <div style={Style.cancelOrder}>Cancel Payment</div>
                    <div style={Style.choicesStyle}>
                        Are you sure you want to cancel this payment?
                    </div>
                    <div>
                        <Button type="danger" style={Style.cancelButtonStyle} onClick={() => cancelPayment()}>Confirm</Button>
                        <Button style={Style.dontCancelButton} onClick={() => setCancelModalVisibility(false)}>Cancel</Button>
                    </div>
            </Modal>
            <Modal
                visible={imageModal}
                footer={null}
                width="500px"
                onCancel={()=>setImageModal(false)}
            >
                <div style={{paddingTop: '20px'}}>
                    <div style={{fontSize: '24px', color: '#2b2d33', marginBottom: '8px'}}>Attachment</div>
                    <img style={{width: '100%'}} src={imageUrl != "" && imageUrl}/>
                </div>
            </Modal>
        </div>
    )
}

export default TransactionTable;