import React, { useRef } from "react";
import {
  useDispatch,
  useSelector,
} from "../../../../__test__/react-redux-hooks";
import multiwalletActions from "../../multiwalletActions";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Button,
  Icon,
  Pagination,
  message,
} from "antd";
import { history } from "../../../../store/history";
import multiwalletServices from "../../multiwalletServices";
import TransferRequestModal from "./transfer_request_modal";
import { jsonToCSV } from "react-papaparse";
import UploadCsvModal from "../transfer_money/uploadCsvModal";
import TablePreviewModal from "../transfer_money/tablePreviewModal";
import OtpModal from "../otp_modal";

const templateData = [
  {
    full_name: "Johny Bravo",
    email: "johny@sample.com",
    contact: "9123456789",
    amount: 200,
  },
  {
    full_name: "John Week",
    email: "weekjohn@ubx.ph",
    contact: "9221234555",
    amount: 300,
  },
  {
    full_name: "Sam Smyth",
    email: "sammy@sample.com",
    contact: "9191231232",
    amount: 400,
  },
];

const defaultCsvTemplate = {
  full_name: "Full Name",
  email: "Email",
  contact: "Mobile Number",
  amount: "Amount",
  transfer_id: "Transfer ID",
};


const SubwalletTable = (props) => {
  const {
    segments,
    data,
    isLoading,
    onApplyFilter,
    onChangePage,
    onAmountChange,
    setLoading,
    onTransferRequestSuccess,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState("");
  const testMode =
    history.location.pathname.indexOf("/test") > -1 &&
    localStorage.getItem("userType") == "CO";
  const [transferRequestResult, setTransferRequestResult] = React.useState({});
  const [transferRequestModal, setTransferRequestModal] = React.useState(false);
  const [uploadModalShow, setUploadModalShow] = React.useState(false);
  const [tablePreviewShow, setTablePreviewShow] = React.useState(false);
  const [tablePreviewData, setTablePreviewData] = React.useState(null);
  const [transferRequestLoading, setTransferRequestLoading] =
    React.useState(false);
  const [transferCsvTemplate, setTransferCsvTemplate] = React.useState(null);
  const [transferCsvTemplateLoading, setTransferCsvTemplateLoading] =
    React.useState(false);

  const userId = localStorage.getItem("userId");
  const csvTemplate = transferCsvTemplate || defaultCsvTemplate;
  const isChild = localStorage.getItem("is_child") === "true";

  const [otpModalShow, setOtpModalShow] = React.useState(false);
  const [otpData, setOtpData] = React.useState(null);
  const [resetTimer, setResetTimer] = React.useState(false);
  const [isEmailOtp, setIsEmailOtp] = React.useState(false);
  const [isOtpLoading, setIsOtpLoading] = React.useState(false);
  const [updatedData, setUpdatedData] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);

  const transferMoneyData = useSelector(
    (state) => state.multiwallet.transferMoneyData
  );

  const definedWalletSettings = JSON.parse(
    localStorage.getItem("defined_wallet_settings")
  );
  const { wallet_transfer_id_enabled: walletTransferIdEnabled = false } =
    definedWalletSettings;

  const tableColumns = [
    {
      title: "User ID",
      dataIndex: "sub_id",
    },
    {
      title: "Name",
      dataIndex: "full_name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "contact",
    },
    ...(walletTransferIdEnabled
      ? [
          {
            title: "Transfer ID",
            dataIndex: "transfer_id",
            width: "20%",
            render: (_, record, index) => (
              <Input
                key={`tranferId-${record.id}`}
                type="text"
                style={{ width: "100%" }}
                onPressEnter={(e) => updateData({ ...record, ...{transfer_id: e.target.value, amount: getAmountInput(record)} }, index)}
                onBlur={(e) => updateData({ ...record, ...{transfer_id: e.target.value, amount: getAmountInput(record)} }, index)}
                onChange={(e) => updateData({ ...record, ...{transfer_id: e.target.value, amount: getAmountInput(record)} }, index)}
                defaultValue={getTransferIdInput(record)}
              />
            ),
          },
        ]
      : []),
    {
      title: "Amount",
      dataIndex: "amount",
      width: "30%",
      render: (_, record, index) => (
        <Input
          key={`amount-${record.id}`}
          type="number"
          style={{ width: "100%" }}
          onPressEnter={(e) => updateData({ ...record, ...{transfer_id: getTransferIdInput(record), amount: e.target.value} }, index)}
          onBlur={(e) => updateData({ ...record, ...{transfer_id: getTransferIdInput(record), amount: e.target.value} }, index)}
          onChange={(e) => updateData({ ...record, ...{transfer_id: getTransferIdInput(record), amount: e.target.value} }, index)}
          onWheel={(e) => {
            e.preventDefault();
            e.target.blur();
          }}
          defaultValue={getAmountInput(record)}
        />
      ),
    },
  ];

  const arrangeData = (res, data) => {
    let goodData = new Array;
    res && res.errors && data.map((arr, i) => {
      arr['remarks'] = res.errors[i][0];
      goodData.push(arr);
    });
    setTablePreviewData(goodData);
  }

  const flatData = (data) =>
    data && Object.keys(data).map((key, i) => {
      return {
        full_name: data[i]['full_name'],
        email: data[i]["email"],
        contact: data[i]["contact"],
        amount: data[i]["amount"],
        ...(walletTransferIdEnabled && {
          transfer_id: data[i]["transfer_id"],
        }),
      };
    });

  const validateManualData = async () => {
    let selectedRows = [];

    if (selectedRowKeys.length > 0) {
      updatedData.map((row, i) => {
        if (selectedRowKeys.includes(row.id)) {
          selectedRows.push(row);
        }
      });
    }

    try {
      const res = await multiwalletServices.validateTransferMoneyCsv(testMode, flatData(selectedRows));

      if(res.status==='success'){
        arrangeData(res, flatData(selectedRows));
        setTablePreviewShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateData = (row, index) => {
    onAmountChange(row, index);
    
    let newData = updatedData;

    if (newData.length == 0) {
      newData.push(row)
      setUpdatedData(newData);
    } else {
      const index2 = newData.findIndex(item => row.sub_id === item.sub_id);
      if (index2 == -1) {
        newData.push(row)
      } else {
        const item = newData[index2];
        newData && newData.splice(index2, 1, {
            ...item,
            ...row,
        });
      }
      setUpdatedData(newData);
    }
  }

  const getAmountInput = (row) => {
    let amountInput = "";
    const index = updatedData.findIndex(item => row.sub_id === item.sub_id);
    if (index > -1) {
      amountInput = updatedData[index]["amount"];
    }
    return amountInput;
  }

  const getTransferIdInput = (row) => {
    let transferIdInput = "";
    const index = updatedData.findIndex(item => row.sub_id === item.sub_id);
    if (index > -1) {
      transferIdInput = updatedData[index]["transfer_id"];
    }
    return transferIdInput;
  }

  const fetchOTP = async () => {
    setIsOtpLoading(true);
    try {
      const res = await multiwalletServices.getOTP(testMode);
      if (res.status === "success") {
        setOtpData(res);
        setOtpModalShow(true);
      }
      setIsOtpLoading(false);
    } catch (error) {
      console.log(error);
      setIsOtpLoading(false);
    }
  };

  const handleEmailOtp = async () => {
    setIsOtpLoading(true);
    try {
      const response = await multiwalletServices.getOtpEmail("transfer_money");
      if (response.status === "success") {
        setOtpData("");
        setIsEmailOtp(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsOtpLoading(false);
  };

  const subusers = () => {
    if (data) {
      return data["results"];
    } else {
      return [];
    }
  };

  const handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      age: 32,
      address: `London, Park Lane no. ${count}`,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  const handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    this.setState({ dataSource: newData });
  };

  const mappedTemplateData = templateData.map((row) => {
    const mappedRow = {};
    Object.entries(row).forEach(([key, value]) => {
      mappedRow[csvTemplate[key]] = value;
    });
    return mappedRow;
  });

  const exportTemplate = () => {
    const dataWithTransferId = mappedTemplateData.map((t) => ({
      ...t,
      [csvTemplate.transfer_id]: "1234",
      [csvTemplate.amount]: t[csvTemplate.amount],
    }));

    const csv = jsonToCSV(
      walletTransferIdEnabled ? dataWithTransferId : mappedTemplateData
    );
    const blob = new Blob([csv]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob, { type: "text/plain" });
    a.download = "subwallet_money_transfer_template.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const columns = tableColumns.map((col) => {
    // if (!col.editable) {
    //   return col;
    // }
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        inputType: col.dataIndex === "amount" ? "number" : "text",
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: (row) => onAmountChange(row, index),
      }),
    };
  });

  const hasEmptyAmount = (data) => {
    const isEmpty = data.every(
      (item) => item.amount && item.amount && item.amount >= 1
    );
    if (isEmpty) {
      return false;
    }
    return true;
  };

  const getTotalAmount = (data) => {
    let total = 0;
    data &&
      data.map((row, i) => {
        total = total + parseFloat(row.amount ? row.amount : 0);
      });
    return total;
  };

  const generatePayload = (transferId) => {
    return {
      field1: {
        name: "Transfer ID",
        value: transferId,
      },
    };
  };

  const addToTransferMoneyBox = async (transfer_money_uid) => {
    let data = [];

    tablePreviewData && tablePreviewData.map((key, i) => {
      if (key.remarks === '') {
        data.push(key);
      }
    });

    try {
      setTransferRequestLoading(true);
      const dataWithPayload = data.map((d) => ({
        ...d,
        payload: generatePayload(d.transfer_id),
      }));
      const params = { transfer_money_uid };
      const res = await multiwalletServices.batchTransferMoneyRequest(
        walletTransferIdEnabled ? dataWithPayload : data,
        params
      );
      setTransferRequestResult(res);
      setTransferRequestModal(true);
      onTransferRequestSuccess();
    } catch (error) {
      message.error(error.message);
    } finally {
      setTransferRequestLoading(false);
      setLoading();
      setSelectedRowKeys([]);
      setSelectedIds([]);
      setUpdatedData([]);
    }
  };

  const getSelectedIndex = () => {
    const data = subusers().map((x) => { return x.id});
    const selectedRows = [];
    data.map((row, i) => {
        if (selectedRowKeys.includes(row)) {
          selectedRows.push(i);
        }
      });
    return selectedRows;
  }

  const tableCheckbox = {
    type: "checkbox",
    selectedRowKeys: getSelectedIndex(),
    onChange: (selectedRowKeys, selectedRows) => {
      handleChangeSelect(selectedRowKeys, selectedRows)
    }
  };

  const handleChangeSelect = (selectedRowKeys1, selectedRows1) => {
    const page = data["page"];
    const selectedIdsWithPage = [];

    selectedRows1.map((row) => {
      selectedIdsWithPage.push({
        page: page,
        id: row.id
      })
    })

    const newData = selectedIds.filter(e => e.page !== page);
    const combinedData = [...new Set([...newData, ...selectedIdsWithPage])];
    setSelectedIds(combinedData);
    setSelectedRowKeys(combinedData.map((r) => r.id))   
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  const changePage = (page, pageSize) => {
    onChangePage(page);
  };

  const onFilterApply = () => {
    onApplyFilter(searchVal);
  };

  const fetchTransferCsvTemplate = async () => {
    try {
      setTransferCsvTemplateLoading(true);
      const params = { is_child: isChild };
      const response = await multiwalletServices.fetchTransferCsvTemplate(
        userId,
        params
      );
      setTransferCsvTemplate(response.data);
    } catch (error) {
      setTransferCsvTemplate(null);
    } finally {
      setTransferCsvTemplateLoading(false);
    }
  };

  React.useEffect(() => {
    // setSelectedRowKeys([]);
    fetchTransferCsvTemplate();
  }, [data]);

  const enableTransferRequestButton =
    selectedRowKeys.length > 0 && !transferRequestLoading;

  const enableBatchActions = !transferCsvTemplateLoading;

  return (
    <div style={styles.root}>
      <div style={styles.body}>
        <div className="twoCol">
          <div className="bold-text-size liveColor top-20">
            Select the sub-wallet you want to transfer funds.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "24px 0",
            }}
          >
            <Input
              placeholder="Search"
              style={{
                width: "200px",
                height: "40px",
                borderRadius: "4px",
                padding: "0 8px 0 0px",
              }}
              suffix={<Icon type="search" style={{ fontSize: "18px" }} />}
              onChange={handleSearch}
              value={searchVal}
            />
            <button
              className={`btn--${segments} btn-height`}
              onClick={onFilterApply}
            >
              Search
            </button>
          </div>
        </div>

        <Table
          rowClassName="table-row-light"
          dataSource={subusers()}
          columns={columns}
          pagination={false}
          rowSelection={{ ...tableCheckbox }}
          loading={isLoading}
        />
        <Pagination
          size="small"
          total={data && data["total"]}
          cd
          defaultPageSize={10}
          pageSize={10}
          defaultCurrent={data && data["page"] ? data["page"] : 1}
          current={data && data["page"] ? data["page"] : 1}
          onChange={(page, pageSize) => {
            // Clear any existing text selection
            window.getSelection().removeAllRanges();

            changePage(page, pageSize);
          }}
          style={{ padding: "12px 0 8px 0", textAlign: "right" }}
        />

        <div className="bottom-32 top-16" align="right">
          <button
            disabled={!enableBatchActions}
            onClick={exportTemplate}
            name="exportBtn"
            className={`outline-btn--${segments} btn-height right-8`}
          >
            Download Template
          </button>
          <button
            disabled={!enableBatchActions}
            className={`outline-btn--${segments} btn-height right-8`}
            onClick={() => setUploadModalShow(true)}
          >
            <Icon type="download" className="right-4" />
            Import Batch list
          </button>
          <button
            disabled={!enableTransferRequestButton || isOtpLoading}
            className={`btn--${segments} btn-height normal-text-size`}
            onClick={() => {
              validateManualData();
            }}
          >
            <Icon type="plus" className="right-4" />
            Submit Transfer for Approval ({selectedRowKeys.length})
          </button>
        </div>
      </div>
      <TransferRequestModal
        data={transferRequestResult}
        visible={transferRequestModal}
        onClose={() => setTransferRequestModal(false)}
      />
      <UploadCsvModal
        segments={segments}
        visible={uploadModalShow}
        closeModal={() => setUploadModalShow(false)}
        exportTemplate={exportTemplate}
        onConfirm={(data) => {
          setUploadModalShow(false);
          setTablePreviewShow(true);
          setTablePreviewData(data);
        }}
        csvTemplate={csvTemplate}
      />
      <TablePreviewModal
        segments={segments}
        visible={tablePreviewShow}
        data={transferMoneyData}
        closeModal={() => setTablePreviewShow(false)}
        addTableData={(data) => {
          fetchOTP();
        }}
        tableData={tablePreviewData}
      />
      <OtpModal
        visible={otpModalShow}
        resetTimer={resetTimer}
        setResetTimer={() => setResetTimer(false)}
        closeModal={() => setOtpModalShow(false) / setIsEmailOtp(false)}
        otpData={otpData}
        onSuccess={(transfer_money_uid) => addToTransferMoneyBox(transfer_money_uid)}
        onResend={fetchOTP}
        onEmailOtp={handleEmailOtp}
        isEmailOtp={isEmailOtp}
      />
    </div>
  );
};

const styles = {
  root: {
    margin: "24px 0",
  },
  transferBtn: {
    fontSize: 16,
    fontWeight: "600",
    padding: "8px, 16px, 8px, 16px",
    border: "1px solid #1DD28B",
    height: 40,
    color: "#FFF",
    backgroundColor: "#1DD28B",
  },

  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px 0",
  },
  body: {
    backgroundColor: "transparent",
    padding: "0px 16px",
    backgroundColor: "#FFF",
    border: "1px solid #E6EAF0",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
  },
  applyBtn: {
    height: 40,
    width: 92,
    fontSize: 16,
    fontWeight: "600",
    borderRadius: 4,
    backgroundColor: "#F5922F",
    color: "#FFF",
  },
};

const EditableSubwalletTable = Form.create()(SubwalletTable);

export default EditableSubwalletTable;
