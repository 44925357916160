import React from 'react'
import {history} from '../../../../../store/history'
import { Grid } from '@material-ui/core';
import { Input, Card, Radio, message, Select, Icon as Icons, Divider} from 'antd';
import {isMobile} from 'react-device-detect'
import IconCheck from '../../../../../static/icons/check_icon.svg';
import axios from 'axios'
import crypto from 'crypto';
import DisclaimerModal from '../../../../account_settings/bux_qr/disclaimerModal';
import MetaTag from '../../../../meta_tag/meta_tag';
import ManualAddMoney from './manual_add_money';

import * as Icon from '../../../../../static/icons/payment_method_icons/index';
import BankInfo from '../../../../../static/icons/bank_info.svg';

import { INSTAPAY_LIST, PCHC_LIST } from '../../../../constants/constants';
import UpayList from '../../../../checkout/NewCheckout/upayList';

let latestToken;

const AddMoney = props => {

    const {Option} = Select;
    const {TextArea} = Input;
 
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    const ubp_enabled = localStorage.getItem('cc_enabled') == 'UnionBank';
    const xendit_enabled = localStorage.getItem('cc_enabled') == 'Xendit';
    const ccEnabled = ubp_enabled || xendit_enabled;
    const isMultiwallet = localStorage.getItem("is_multiwallet")
    const userType = localStorage.getItem("userType")
    const userEmail = localStorage.getItem("email")
    const isSubDomain = process.env.REACT_APP_ROOT_URL != window.location.hostname;
    const has_cashbux_privilege = localStorage.getItem("has_cashbux_privilege");
    const instapay_payment_channel = localStorage.getItem("instapay_payment_channel");

    let trade_name = "";
    if (isSubDomain) {
        let remove_part = `.${process.env.REACT_APP_SUB_URL}`;
        trade_name = window.location.hostname.replace(remove_part, "");
    } else {
        trade_name = localStorage.getItem('tradeName');
    }

    //States
    const [disabled, setDisable] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [showDisclaimer, setShowDisclaimer] = React.useState(false) 
    const [terms, setTerms] = React.useState(false) 
    const [disabledChannels, setDisabledChannels] = React.useState([])
    const [fees, setFees] = React.useState()

    //Details
    const [amount, setAmount] = React.useState(parseFloat(0).toFixed(2))    
    const [description, setDescription] = React.useState("")

    //Channel State
    const [paymentMethod, setPaymentMethod] = React.useState('');
    const [channel, setChannel] = React.useState('');
    const [bank, setBank] = React.useState('Select a bank');
    const [sur, setSur] = React.useState()
    const [descError, setDescError] = React.useState(null);
    const [amountError, setAmountError] = React.useState(null);
    const [channelError, setChannelError] = React.useState(null);

    const [imageBlob, setImageBlob] = React.useState("")
    const [bankModalVisibility, setBankModalVisibility] = React.useState(false)

    const [detailPaymentMethod, setDetailPaymentMethod] = React.useState('');
    const [mobileDetailsShow, setMobileDetailsShow] = React.useState(false);

    const MAX_VALUE = 999999999.99;

    const segments = localStorage.getItem('segments');
    const list_bank = ['bank', 'BPIA', 'UBPB', 'RCBC', 'BDO', 'MBTC']

    const channel_list = [
        {'name': '7-Eleven','code': '711_direct', 'icon': Icon.SevenEleven, 'enabled': testMode? true : disabledChannels.indexOf('711_direct') == -1},
        {'name': 'Bayad Center','code': 'bayad_center', 'icon': Icon.BayadCenter, 'enabled': testMode? true : disabledChannels.indexOf('bayad_center') == -1},
        {'name': 'Cebuana','code': 'CEBL', 'icon': Icon.Cebuana, 'enabled': testMode? true : disabledChannels.indexOf('CEBL') == -1},
        {'name': 'ECPay','code': 'ECPY', 'icon': Icon.Ecpay, 'enabled': testMode? true : disabledChannels.indexOf('ECPY') == -1},
        // {'name': 'LBC','code': 'LBC', 'icon': Lbc, 'enabled': testMode? true : disabledChannels.indexOf('LBC') == -1},
        {'name': 'MLhuillier','code': 'MLH', 'icon': Icon.ML, 'enabled': testMode? true : disabledChannels.indexOf('MLH') == -1 && amount >= 180 && userType == 'CO'},
        {'name': 'Robinsons','code': 'RDS', 'icon': Icon.Robinson, 'enabled': testMode? true : disabledChannels.indexOf('RDS') == -1},
        {'name': 'Palawan','code': 'PLWN', 'icon': Icon.Palawan, 'enabled': testMode? true : disabledChannels.indexOf('PLWN') == -1},
        {'name': 'SM','code': 'SMR', 'icon': Icon.Sm, 'enabled': testMode? true : disabledChannels.indexOf('SMR') == -1},
        {'name': 'RD Pawnshop','code': 'RDP', 'icon': Icon.RD, 'enabled': testMode? true : disabledChannels.indexOf('RDP') == -1},
        {'name': 'RuralNet','code': 'RLNT', 'icon': Icon.Rural, 'enabled': testMode? true : disabledChannels.indexOf('RLNT') == -1},
        // {'name': 'Expresspay','code': 'EXPY', 'icon': Expresspay, 'enabled': testMode? true : disabledChannels.indexOf('EXPY') == -1},
        {'name': 'Pay&Go','code': 'payngo', 'icon': Icon.BTI, 'enabled': testMode? true : disabledChannels.indexOf('payngo') == -1},
        {'name': 'DA5','code': 'da5', 'icon': Icon.DA5, 'enabled': testMode? true : disabledChannels.indexOf('da5') == -1},
        {'name': 'Posible','code': 'posible', 'icon': Icon.Posible, 'enabled': testMode? true : disabledChannels.indexOf('posible') == -1},
        {'name': 'USSC','code': 'ussc', 'icon': Icon.USSC, 'enabled': testMode? true : disabledChannels.indexOf('ussc') == -1},
        // {'name': 'Rustan','code': 'rustan', 'icon': Rustan, 'enabled': testMode? true : paymentObj.disabledChannels && paymentObj.disabledChannels.indexOf('rustan') == -1},
        // {'name': 'Shopwise','code': 'shopwise', 'icon': Shopwise, 'enabled': testMode? true : paymentObj.disabledChannels && paymentObj.disabledChannels.indexOf('shopwise') == -1},
        // {'name': 'Wellcome','code': 'wellcome', 'icon': Wellcome, 'enabled': testMode? true : disabledChannels.indexOf('wellcome') == -1},
        {'name': 'Tambunting','code': 'tmbntng', 'icon': Icon.Tambunting, 'enabled': testMode? true : disabledChannels.indexOf('tmbntng') == -1},
        // {'name': 'CVM Pawnshop','code': 'cvm', 'icon': CVM, 'enabled': testMode? true : disabledChannels.indexOf('cvm') == -1},   
        {'name': 'Villarica','code': 'villarica', 'icon': Icon.Villarica, 'enabled': testMode? false : disabledChannels.indexOf('villarica') == -1},
        {'name': 'Petnet, Inc','code': 'petnet', 'icon': Icon.Petnet, 'enabled': testMode? false : disabledChannels.indexOf('petnet') == -1},
    ]

    const bank_choices = [
        {
            code: "UBPB",
            enabled: disabledChannels.indexOf('UBPB') == -1,
            icon: Icon.UB,
            name: 'Unionbank'
        },
        {
            code: "BPIA",
            enabled: disabledChannels.indexOf('BPIA') == -1,
            icon: Icon.Bpi,
            name: 'BPI'
        },
        {
            code: "RCBC",
            enabled: disabledChannels.indexOf('RCBC') == -1,
            icon: Icon.Rcbc,
            name: 'RCBC'
        },
        {
            code: "MBTC",
            enabled: disabledChannels.indexOf('MBTC') == -1,
            icon: Icon.Metrobank,
            name: 'Metrobank'
        },
    ]

    const banks_ewallets_choices = [
        { 
            'code': instapay_payment_channel, 
            'enabled': testMode ? false : disabledChannels.indexOf(instapay_payment_channel) == -1, 
            'icon': Icon.MainInstapay,
            'groupIcon': Icon.InstapayPartners,
            'name': 'Instapay'
        },
        { 
            'code': 'paygate', 
            'enabled': testMode ? false : disabledChannels.indexOf('paygate')  == -1, 
            'icon': Icon.MainPchc,
            'groupIcon': Icon.PaygatePartners,
            'name': 'PCHC'
        },
    ]

    const banks = [ 
        {'surcharge': '10', 'ac': 'UBPB', 'name': 'UnionBank Internet Banking'},
        {'surcharge': '15', 'ac': 'BPIA', 'name': 'Bank of the Philippine Islands'},
        {'surcharge': '5', 'ac': 'RCBC', 'name': 'RCBC Online Banking'},
        {'surcharge': '5', 'ac': 'MBTC', 'name': 'Metrobank Online Banking'},
    ]

    const gcash_enabled =  disabledChannels.indexOf('gcash') == -1;
    const grabpay_enabled =  disabledChannels.indexOf('grabpay') == -1;
    const maya_enabled =  disabledChannels.indexOf('maya') == -1;
    const bnpl_enabled = disabledChannels.indexOf('billease') == -1;
    const snpl_enabled = disabledChannels.indexOf('billease_sn') == -1;
    
    const disclaimerChannels = ["BPIA", "UBPB", "RCBC", "gcash", "grabpay", "maya", "visamc","ubpcs", "billease", "billease_sn"]

    const ewallets = [
        {
            code: "gcash",
            name: "GCash",
            enabled: testMode? true : gcash_enabled,
            icon: Icon.Gcash
        },
        {
            code: "grabpay",
            name: "GrabPay",
            enabled: testMode? true : grabpay_enabled,
            icon: Icon.Grabpay
        },
        {
            code: "maya",
            name: "Maya",
            enabled: testMode ? true : maya_enabled,
            icon: Icon.Maya,
        },
    ]

    const autoTransfers = [
        {
            code: "unionbank_transfer",
            name: "UnionBank",
            enabled: testMode ? false : disabledChannels.indexOf('unionbank_transfer') == -1,
            icon: Icon.UB,
        },
    ];

    const errorMsg = (text) => {
        message.destroy()
        message.error(text, 3)
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        setLoading(false)

    }

    async function getFees(toPaid) {

        let generatedRequestToken = crypto.randomBytes(16).toString('hex');
        latestToken = generatedRequestToken
        let endpoint = testMode ? '/api/sandbox/get_channel_fee/' : '/api/get_channel_fee/'
        let url = process.env.REACT_APP_API_URL + endpoint

        let params = {
            trade_name,
            amount: toPaid ? toPaid : amount,
            token: generatedRequestToken,
        }

        let header = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            let response = await axios.post(url, params, header)
            console.log(response.data)

            if(generatedRequestToken === response.data.token) {
                setFees(response.data.fees)
                setDisabledChannels(response.data.disabled_channels)
            }
        }
        catch(error) {
            console.log(error)
        }
    }

    function gettingParams() {
        console.log(imageBlob)
        let channel_checkout = channel;
        
        let params = {}
        setShowDisclaimer(false)
        
        if (amount > 80000 && userType === "SW" && has_cashbux_privilege === "true") {
          setAmountError("The maximum supported amount is ₱80,000.00. No payment channels are available for higher amounts.")
        }

        if(amount == 0) {
            setAmountError("Amount is required")
        }
        else if(description == "") {
            setDescError("Please fill up description")
        }
        else if (channel == "" && imageBlob == "") {
            setChannelError("Please select a payment channel")
        }
        else if(disclaimerChannels.includes(channel) && !showDisclaimer){
            setShowDisclaimer(true)
            setLoading(false)
        }
        else {
            setDescError(null);
            setAmountError(null);
            if(channel == '' && imageBlob != ""){
                const formData = new FormData();
                formData.append('image_proof', imageBlob, 'image_proof.jpg')
                formData.append('amount', amount)
                formData.append('note', description)
                formData.append('channel', "manual_add")

                checkoutQr(formData)

            }
            else{
                if(channel == 'EXPY'){
                    channel_checkout = 'ECPY'
                }
                params = {
                    amount, 
                    channel: channel_checkout,
                    note: description,
                }
                checkoutQr(params)

            }
        }
    }

    async function checkoutQr(params) {
        setLoading(true)
        try {
            let header = {
                headers: {
                    Authorization: "Token " + localStorage.getItem("jwtToken")
                }
            }
            
            let endpoint = testMode ? '/api/sandbox/multiwallet/add_money/' : '/api/multiwallet/add_money/'
            let url = process.env.REACT_APP_API_URL + endpoint
            let response = await axios.post(url, params, header)
            
            console.log(response.data)
            if(response.data.status == "success") {
                if(response.data.banking_url) {
                    window.location.href = response.data.banking_url;
                }

                else {
                    
                    window.location.href= testMode ? `/test/payment/${response.data.uid}` : `/payment/${response.data.uid}`
                }
                setLoading(false)
            }

            else {
                if(response.data && response.data.message === 'Invalid channel selected') {
                    setChannelError(response.data.message);
                } else {
                    errorMsg(response.data.message)
                    setLoading(false)
                }
                setLoading(false)
            }
        }

        catch (error) {
            message.destroy();
            message.error('An error has occured. Please try again later.');
            console.log(error);
        }
    } 

    function handleSelectBank(value){
        
        setChannel(banks[value].ac)
        setBank(banks[value].name)
        setSur(banks[value].surcharge)
    }


    const channels = () => {
        return (
            channel_list.some(item => item.enabled) &&
            <div className='checkout-channel-card-wrapper'>
                {
                    channel_list && channel_list.map((item, i) => {
                        return (
                            item.enabled && 
                            <div className='checkout-channel-card'>
                                <button
                                    id={item.code}
                                    className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                                    onClick={() => setChannel(item.code)/setDetailPaymentMethod(item.name)}>
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>
                                    <img src={item.icon}
                                        alt="icon"
                                        style={{ maxWidth: '48%'}} />
                                </button>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const web_content = () => {
        return (
            bank_choices.some(item => item.enabled) &&
            <div className='checkout-channel-card-wrapper' >
                {bank_choices.map((item, i) => {
                    return (
                        item.enabled &&
                        <div key={i} className='checkout-channel-card'>
                            <button
                                className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                onClick={() => handleSelectBank(i) / setDetailPaymentMethod(item.name)}
                                disabled={!item.enabled}
                            >
                                <div style={{ position: 'absolute', left: 16 }} >
                                    {
                                        channel != item.code ?
                                            <Radio />
                                            :
                                            <img src={IconCheck} alt="check"/>
                                    }
                                </div>
                                
                                <img alt="icon" src={item.icon} style={{maxWidth: '48%', marginTop: item.code == 'paygate' ? -10 : item.code == instapay_payment_channel ? 16 : 0}} />
                            </button>
                        </div>
                    )
                })}
            </div>
        )
    }

    
    const ewallet_content = () => {
        return (
            ewallets.some(item => item.enabled) &&
            <div style={{ width: '100%'}} >
                <div className='checkout-channel-card-wrapper' >
                    {ewallets.map((item, i) => {
                        return (
                            item.enabled &&
                            <div div className='checkout-channel-card' >
                                <button
                                    className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                    onClick={() => setChannel(item.code) / setDetailPaymentMethod(item.name)}
                                    disabled={!item.enabled}
                                >
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>

                                    <img alt="icon" src={item.icon} style={{ maxWidth: '48%', marginLeft: 15 }} />
                                    <div style={{ marginTop: '14px', color: '#000000', textoverflow: 'ellipsis' }}></div>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const banks_wallets_content = () => {
        return (
            banks_ewallets_choices.some(item => item.enabled) &&
            <div style={{width: '100%'}}>
                {
                    banks_ewallets_choices.map((item, i) => {
                        return (
                            item.enabled &&
                            <div item style={{width: '100%'}}>
                                <button
                                    id={item.code}
                                    style={{ width: '100%', height: isMobile? '100%' : '92px', marginTop: i !== 0 && 20}}
                                    className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                                    onClick={() => setChannel(item.code) / setDetailPaymentMethod(item.name)}>
                                 <div style={{display: 'flex', justifyContent: 'flexStart', alignItems: 'center', width: '100%', height: isMobile && '100%', flexDirection: isMobile ? 'column' : 'row'}}>
                                    <div style={{width: isMobile? '100%' : '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: isMobile && '100%', padding: isMobile && '12px 8px'}}>
                                        <div style={{ position: 'absolute', left: 16 }} >
                                                {
                                                    channel != item.code ?
                                                        <Radio />
                                                        :
                                                        <img src={IconCheck} alt="check"/>
                                                }
                                            </div>
                                        <div style={{display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'row' : 'column', justifyContent: 'center'}}>
                                                <img src={item.icon}
                                                    alt="icon"
                                                    style={{
                                                        maxWidth: '82%',
                                                        // marginBottom: isMobile && item.code === 'RDP' ? 4 : '10px'
                                                    }} />
                                                <span style={{ color: 'rgba(43, 45, 50, 0.64)', fontWeight: '500', fontSize: '10px', paddingLeft: isMobile && '4px' }} >{item.code === instapay_payment_channel ? '1 Business Day' : '1-2 Business Days'}</span>
                                        </div>
                                    </div>
                                    <Divider type={isMobile? 'horizontal' : 'vertical'} style={{width: isMobile? '103%' : '1px', height: !isMobile && '92px', margin: 0}} />
                                    <div style={{width: isMobile? '100%' : '75%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: isMobile && '20px 8px', position: 'relative'}}>
                                        <img width={isMobile? '100%' : '75%'} alt={item.code} src={item.groupIcon} />
                                        <a onClick={() => setBankModalVisibility(true)} hidden={item.code === 'paygate'} style={{
                                              position: 'absolute',
                                              top: isMobile? '46%' : '42%',
                                              right: '17%',
                                              height: isMobile? '28%' : '20px',
                                              width: '10vw',
                                              transform: 'translate(50%, 50%)',
                                        }}></a>
                                    </div>
                                 </div>
                                </button>
                            </div>
                        )
                    })
                }
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '100%', marginTop: '30px'}}>
                    <img alt='Banks and E-wallets' src={Icon.BanksEwallerPoweredBy} />
                </div>
            </div>
        )
    }

    const card_payment = []
    
    if(xendit_enabled && !disabledChannels.includes('visamc') && userType != 'SW'){
        card_payment.push(
            {
                code: "visamc",
                enabled: true,
                icon: Icon.Xendit,
                name: 'Visa/MasterCard'
            },
        )
    }

    else if(ubp_enabled && !disabledChannels.includes('ubpcs') && has_cashbux_privilege){
        card_payment.push(
            {
                code: "ubpcs",
                enabled: true,
                icon: Icon.VISAMastercardLogo,
                name: 'VISA/MASTERCARD'
            },
        )
    }

    const card_content = () => {
        return (
            <div className='checkout-channel-card-wrapper' > {
            card_payment.map((item, i) => {
                return (
                    item.enabled &&
                    <div div className='checkout-channel-card'  item>
                        <button
                            id={item.code}
                            className={channel == item.code ? 'otc-button-selected' : 'otc-button-not-selected'}
                            onClick={() => setChannel(item.code) / setDetailPaymentMethod(item.name)}>
                            <div style={{ position: 'absolute', left: 16 }} >
                                {
                                    channel != item.code ?
                                        <Radio />
                                        :
                                        <img src={IconCheck} alt="check"/>
                                }
                            </div>
                            <img src={item.icon}
                                alt="icon"
                                style={{
                                    maxWidth: '48%',
                                }} />
                        </button>
                    </div>
                )
            })
            }
        </div>
        )
    }

    const merchantAccountTransfersContent = () => {
        return (
            <div style={{ width: '100%'}} >
                <div className='checkout-channel-card-wrapper' >
                    {autoTransfers.map((item, i) => {
                        return (
                            item.enabled &&
                            <div div className='checkout-channel-card' >
                                <button
                                    className={channel == item.code ? 'ol-ew-btn-selected' : 'ol-ew-btn-not-selected'}
                                    onClick={() => setChannel(item.code) / setDetailPaymentMethod(item.name)}
                                    disabled={!item.enabled}
                                >
                                    <div style={{ position: 'absolute', left: 16 }} >
                                        {
                                            channel != item.code ?
                                                <Radio />
                                                :
                                                <img src={IconCheck} alt="check"/>
                                        }
                                    </div>
                                    <img alt="icon" src={item.icon} style={{ maxWidth: '48%', marginLeft: 15 }} />
                                    <div style={{ marginTop: '14px', color: '#000000', textoverflow: 'ellipsis' }}></div>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const manual_content = () => {
        return(
            <ManualAddMoney segments={segments} setImageBlob={setImageBlob}/>
        )
    }

    const paymentMethodList = [
        {
            hidden: false,
            code: 'OTC',
            text: 'Over-the-counter',
            iconSelected: Icon.OTC_Selected,
            icon: Icon.OTC,
            content: channels()
        },
        {
            hidden: false,
            code: 'WB',
            text: 'Web Banking',
            iconSelected: Icon.WB_Selected,
            icon: Icon.WB,
            content: web_content()
        },

    ]

    paymentMethodList.push(
    {
        hidden: false,
        code: 'EW',
        text: 'E-Wallets',
        iconSelected: Icon.EW_Selected,
        icon: Icon.EW,
        content: ewallet_content()
    })

    paymentMethodList.push(
        {
            hidden: testMode ? true : userType == "SW",
            code: 'BEW',
            text: 'Banks and E-wallets',
            iconSelected: Icon.CC_Selected,
            icon: Icon.BanksEwalletsIcon,
            content: banks_wallets_content()
        }
    )
    
    paymentMethodList.push(
        {
            hidden: card_payment.length ===  0 || (["ME", "SW"].includes(userType) && has_cashbux_privilege === false),
            code: 'CC',
            text: 'Credit / Debit Cards',
            iconSelected: Icon.CC_Selected,
            icon: Icon.CC,
            content: card_content()
        }
    )

    if (autoTransfers.some(item => item.enabled)) {
        paymentMethodList.push({
            code: 'MAT',
            text: 'Merchant Account Transfers',
            icon: Icon.Manual,
            content: merchantAccountTransfersContent()
        })
    }

    if(userType == 'CO' && isMultiwallet == 'true'){
        paymentMethodList.push(
            {
                code: 'MP',
                text: 'Manual Bank Transfer',
                iconSelected: Icon.ManualSelected,
                icon: Icon.Manual,
                content: manual_content()
            }
        )
    }

    function settingAmount(val) {
      setPaymentMethod('');
      setChannel('');

      if (val > MAX_VALUE) {
        return;
      }

      // Helper function to check valid number format
      const isValidNumberFormat = (value) => {
        let splitted_val = value.split(".");
        return splitted_val.length === 1 || (splitted_val.length === 2 && splitted_val[1].length <= 2);
      };

      // If CashBux User, allow more than 80k amount
      if (userType === "SW" && has_cashbux_privilege === "true") {
        if (isValidNumberFormat(val)) {
            setAmount(val);
            return;
        }
      }

      // If not CashBux User, apply standard validation
      if ((val.length <= 10 && val <= 80000.00) || userType === 'CO') {
        if (isValidNumberFormat(val)) {
            setAmount(val);
        }
      }
    }

    return(
        <div>
            <MetaTag title="Add Money"/>
            <DisclaimerModal hasDisclaimer={disclaimerChannels} showModal={showDisclaimer} hideModal={setShowDisclaimer} channel={channel} proceed={gettingParams} />
            <UpayList channel={channel} modalOpen={bankModalVisibility} modalClose={setBankModalVisibility} instaList={INSTAPAY_LIST} pchcList={PCHC_LIST} />
            
            <div style={{padding: isMobile ? '26px 16px 25px 16px' : '30px 138px 20px 138px'}}>
                <div className="multiwallet-details-card bottom-32">
                    <div style={Style.text}>
                        Payment instructions will be sent to <b>{userEmail}</b>
                    </div>
                </div>
                <div className="multiwallet-details-card bottom-32">
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                        <div style={{fontWeight: 'bold', fontSize: '16px', color: '#2b2d32'}}>Add Money Details</div>
                    </div>
                    <div>
                        <div className="smallTextStyle liveColor">Enter Amount</div>
                        <Input size="large"
                            type='number'
                            style={{zIndex: 0, border: amountError && '1px solid #E24C4C'}}
                            value={amount}
                            onBlur={() => getFees()}
                            addonBefore={<div>₱</div>}
                            onChange={(e) => {settingAmount(e.target.value); setAmountError(null);}}
                            onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                            }}
                        />
                        <label style={Style.errorFont} hidden={!amountError}>{amountError}</label>
                    </div>

                    <div className="top-32">
                        <div className="smallTextStyle liveColor">Description</div>
                        <TextArea placeholder="Description" 
                            value={description} rows={4}
                            maxLength={200}
                            onChange={(e) => { setDescription(e.target.value); setDescError(null);}}
                            style={{zIndex: 0, border: descError && '1px solid #E24C4C'}}
                        />
                        <label style={Style.errorFont} hidden={!descError}>{descError}</label>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', margin: 'auto'}}>
                    <div className="wc-header">Choose Add Money Method</div>
                    <div style={{display: 'flex',}}>
                        <div style={{fontSize: '16px', color: '#2b2d33', marginRight: '10px'}} >Add money method:</div>
                        <div style={{marginRight: '20px'}}className="wc-header">{detailPaymentMethod}</div>
                        <button 
                            style={{marginTop: '-10px'}} 
                            className={`btn--${segments} btn-height`} 
                            onClick={()=>gettingParams()} 
                            loading={loading}>Proceed</button>
                    </div>
                </div>
                <label style={Style.errorFont} hidden={!channelError}>{channelError}</label>
                <Card
                    className='multiwallet-payment-method top-20'
                    bodyStyle={{padding: '32px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px'}}>
                        <div style={{fontWeight: 'bold', fontSize: '16px', color: '#2b2d32'}}>Choose Payment Method</div>
                    </div>
                    {
                        paymentMethodList.map((item,i)=>{
                            return(
                                item.content &&
                                <div style={{marginTop: '10px'}} hidden={item.hidden}>
                                    <div style={{
                                        padding: '10px 0px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#FFFFFF',
                                    }}>
                                        <div style={{ backgroundColor: '#E9EEF4', padding: '12px 20px', width: '100%', cursor: isMobile ? 'pointer' : 'default', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <img src={item.icon} style={{ marginRight: '10px', color: '#2B2D32' }} />
                                                <span style={{ fontWeight: '500',color:'#2B2D32'}}>
                                                    {item.text}
                                                </span>
                                            </div>
                                            <div hidden={!isMobile}>
                                                <Icons type={item.code === paymentMethod? 'up' : 'down'} />
                                            </div>
                                        </div>
                                    </div>
                                    <Grid 
                                        spacing={1} 
                                        container 
                                        style={{padding: '20px 0px 20px 0px', display: 'flex', justifyContent: !isMobile ? 'flex-start' : 'center'}}
                                    >
                                        {item.content}
                                    </Grid>
                                </div>
                                    
                            )
                        })
                    }
                </Card>
                <div style={{display: 'flex', justifyContent: 'flex-end', margin: '30px 0px 20px 0px'}}>
                    <div style={{display: 'flex',}}>
                        <div style={{fontSize: '16px', color: '#2b2d33', marginRight: '10px'}} >Add money method:</div>
                        <div style={{marginRight: '20px'}}className="wc-header">{detailPaymentMethod}</div>
                        <button 
                            style={{marginTop: '-10px'}} 
                            className={`btn--${segments} btn-height`} 
                            onClick={()=>gettingParams()} 
                            loading={loading}>
                                Proceed
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Style = {
    text:{
        fontSize: '14px',
        color: '#000'
    },
    label:{
        fontSize: '14px',
        color: 'rgba(43, 45, 50, 0.8)',
        marginBottom: '5px'
    },
    detailsCard: {
        padding: '23px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        backgroundColor: '#fff'
    },
    paymentMethodCard: {
        borderRadius: '10px',
        border: '1px solid #E6EAF0',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        marginTop: '12px'
    },
    choicesDisabled: {
        border: '2px solid #e6eaf0',
        boxSizing: 'border-box',
        opacity: '0.6',
        borderRadius: '10px',
        background: 'rgba(29, 130, 184, 0.06)',
        width: isMobile ? '94px' : '138px',
        height: isMobile ? '114px' : '138px',
        marginTop: '12px',
        position: 'relative',
        paddingTop: '20px',
    },
    choicesSelected:{
        outline: 0,
        border: '2px solid #1DD28B',
        boxSizing: 'border-box',
        borderRadius: '10px',
        background: '#fff',
        marginTop: '3px',
        paddingTop: '20px',
        width: isMobile ? '94px' : '138px',
        position: 'relative',
        height: isMobile ? '114px' : '138px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
    },
    choices:{
        border: '2px solid #e6eaf0',
        boxSizing: 'border-box',
        borderRadius: '10px',
        background: '#fff',
        paddingTop: '20px',
        marginTop: '3px',
        width: isMobile ? '94px' : '138px',
        height: isMobile ? '114px' : '138px',
        position: 'relative',
        cursor: 'pointer',
    },

    disabledEwallet:{
        border: '2px solid #e6eaf0',
        boxSizing: 'border-box',
        opacity: '0.6',
        borderRadius: '10px',
        background: 'rgba(29, 130, 184, 0.06)',
        width: '100%',
        height: '80px',
        marginTop: '12px',
        position: 'relative',
        paddingTop: isMobile ? '30px' : '20px',
    },

    selectedEwallet: {
        outline: 0,
        border: '2px solid #1DD28B',
        boxSizing: 'border-box',
        borderRadius: '10px',
        background: '#fff',
        marginTop: '12px',
        paddingTop: isMobile ? '30px' : '20px',
        width: '100%',
        position: 'relative',
        height: '80px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        cursor: 'pointer',
    },

    notSelectedEwallet: {
        border: '2px solid #e6eaf0',
        boxSizing: 'border-box',
        borderRadius: '10px',
        background: '#fff',
        paddingTop: isMobile ? '30px' : '20px',
        marginTop: '12px',
        width: '100%',
        height: '80px',
        position: 'relative',
        cursor: 'pointer',
    },

    proceedBtn: {
        width: '129px',
        height: '48px',
        backgroundColor: '#F5922F',
        borderRadius: '4px',
        border: '1px solid #F5922F',
        color: '#fff',
        fontWeight: '600',
        fontSize :'20px',
        marginTop: '20px',
        float: 'right'
    },
    errorFont: {
        fontSize: 12,
        color: '#E24C4C'
      },
}

export default AddMoney
