import React from 'react';
import axios from 'axios';
import { Handle401 } from '../../components/handle401/handle401';
import { Card, Button, Typography, Divider, Icon, message, Spin } from 'antd';
import InfoCard from './info_card';
import { CameraIcon } from '../../static/new_segment/icons';
import { DefaultCoverPhoto } from '../../static/new_segment/background_images_svg';
import myProfileServices from '../../services/my_profile';
import noProfile from "../../static/icons/profile.svg";
import { isMobile } from 'react-device-detect';
import { history } from '../../store/history';
import MetaTag from '../../components/meta_tag/meta_tag';
import { useLocation } from 'react-router';
import { getLatestBalance } from '../../components/get_balance/get_balance';
const userType = localStorage.getItem('userType');

const MyProfile = () => {
    const segments = localStorage.getItem('segments');
    const subuserRole = localStorage.getItem('subuser_role');
    const subwalletType = localStorage.getItem('subwallet_type')

    const [profilePicURL, setProfilePicURL] = React.useState(null);
    const [businessData, setBusinessData] = React.useState([]);
    const [personalData, setPersonalData] = React.useState([]);
    const [dashboardData, setDashboardData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const testMode = (history.location.pathname.indexOf('/test') > -1);
    let profilePicUpload = React.useRef();
    const location = useLocation();
    const [isSuccess, setIsSuccess] = React.useState(location.state ? location.state.isSuccess : false);
    const [notifMsg, setNotifMsg] = React.useState(location.state ? location.state.notifMsg : null);
    const [kybBusinessOwner, setKybBusinessOwner] = React.useState('');
    const businessName = (['me_', 'gig'].includes(segments) || userType === 'SW') && personalData && personalData.business ? personalData.business.name : businessData && businessData.business_name;
    const businessOwner = personalData && personalData.first_name ? personalData.first_name + ' ' + personalData.last_name : '';

    if (isSuccess) {
        setTimeout(() => {
            setIsSuccess(false);
            history.replace({ pathname: '/my_profile', state: { isSuccess: false } });
        }, 6000);
    }

    const notif = (
        <div
            style={{
                backgroundColor: 'rgba(29, 210, 139, 0.1)',
                fontSize: 12,
                fontWeight: 'normal',
                width: '100%',
                borderRadius: 4,
                padding: '8px 16px',
                textAlign: 'center',
                display: 'flex',
                // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.24)',
                color: '#000',
                borderLeft: '5px solid #1DD28B',
                margin: !isMobile ? '0 0 24px 0' : '16px 0 0px 0'
            }}
            hidden={!isSuccess}
        >{notifMsg}</div>
    )

    function resizeMe(img) {
        const max_width = 2000;
        const max_height = 2000;

        var canvas = document.createElement('canvas');

        var width = img.width;
        var height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        return canvas.toDataURL("image/png", 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)

        // you can get BLOB too by using canvas.toBlob(blob => {});

    }

    function dataURItoBlob(dataUri) {
        var byteString = atob(dataUri.split(',')[1]);
        var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        return blob
    }

    async function uploadProfilePic(dataUri) {
        var blob = dataURItoBlob(dataUri);

        const formData = new FormData();

        formData.append('profile_picture', blob, 'id_image.png')

        let res = await myProfileServices.uploadProfilePic(formData);

        let res2 = await fetch(res)
        if (res2.ok) {
            message.success('Upload profile photo success')
            window.location.reload()
        }

        else {
            message.error("ERROR" + res.status, 3)
        }
        setProfilePicURL(res.data.url)

    }

    const handleUploadImage = (info, type) => {
        // read the files
        var reader = new FileReader();
        reader.readAsArrayBuffer(info);

        reader.onload = function (event) {
            // blob stuff
            var blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            var blobURL = window.URL.createObjectURL(blob); // and get it's URL

            if (type == "profilePicID") {
                setProfilePicURL(blobURL)
            }
            // helper Image object
            var image = new Image();
            image.src = blobURL;
            image.onload = function () {
                // have to wait till it's loaded
                var resized = resizeMe(image); // resized image url
                uploadProfilePic(resized)
            }
        };
    }

    const getDashboardNow = async () => {
        let yourConfig = {
            headers: {
                Authorization: "Token " + localStorage.getItem("jwtToken")
            }
        }

        try {
            const url = '/api/dashboard/';
            let response = await axios.get(
                process.env.REACT_APP_API_URL + url, yourConfig
            );
            setDashboardData(response.data)
        }

        catch (error) {
            if ((error.response) && (error.response.status === 401)) {
                Handle401()
            }

        }
    }    

    const getProfileDetails = async () => {
        const userId = localStorage.getItem('userId')
        setIsLoading(true);
        try {
            const res1 = await myProfileServices.getProfileDetails();
            const res2 = userType === 'CO' || (userType === 'SW' && subuserRole === 'CO') || ['CMP','BRN'].includes(subwalletType) 
                ? await myProfileServices.getKybDetails(userId) 
                : await myProfileServices.getKycDetails(userId);
            const res3 = await myProfileServices.getProfilePic();

            if (res1 && res1.username) {
                setPersonalData(res1);
            }
            if (res2 && res2.step) {
                setBusinessData(res2);
                filterBusinessOwner(res2);
            }
            if (res3.profile_picture) {
                localStorage.setItem("profilePicture", res3.profile_picture);
                setProfilePicURL(res3.profile_picture);
            } else {
                setProfilePicURL(null);
            }
        } catch (error) {

        }
        setIsLoading(false);
    }

    const filterBusinessOwner = (data) => {
        let owner = '';
        data && data['contact_persons'] && data['contact_persons'].map((d, i) => {
            if (d.department === 'MA') {
                owner = d.first_name + " " + d.last_name;
            }
        })
        setKybBusinessOwner(owner)
    }

    React.useEffect(() => {
        if (testMode) {
            history.push('/dashboard');
        }
        getProfileDetails();
        getLatestBalance();
        getDashboardNow();
    }, [])
    console.log('BUSINESS_DATA', personalData)
    return (
        <div style={styles.root}>
            <MetaTag title='My Profile' />
            <div hidden={isMobile}>
                {notif}
            </div>
            <Card
                style={styles.cardRoot}
                bodyStyle={styles.cardBody}
            >
                <div hidden={!isMobile} style={styles.mobileHeader}>
                    <a className={`link-text--${segments}`} onClick={() => history.push('/overview')}><Icon type='arrow-left' theme='outlined' /></a>
                    <Typography style={styles.title}>Profile</Typography>
                </div>
                <div style={styles.titleDiv}>
                    {/* <img src={DefaultCoverPhoto} alt='No Image' /> */}
                    {/* <img alt={'No Image'} src={'https://wallpaperaccess.com/full/505471.jpg'} height={'50%'} /> */}
                    <div style={{ ...styles.coverBG, backgroundImage: `url(${DefaultCoverPhoto})` }}></div>
                    <input type="file"
                        ref={profilePicUpload}
                        style={{ display: 'none' }}
                        onChange={(e) => handleUploadImage(e.target.files[0], "profilePicID")}
                        accept=".jpg,.png,.jpeg"
                    >
                    </input>
                    <Button style={styles.uploadBtn} onClick={() => profilePicUpload.current.click()} hidden>Update cover photo</Button>
                    <div style={styles.logoDiv}>
                        <div style={{ position: 'relative' }}>
                            <div style={styles.userLogoDiv} >
                                <img style={styles.userLogo} src={profilePicURL ? profilePicURL : noProfile} alt='No Image' />
                            </div>
                            <div style={styles.cameraDiv} >
                                <input type="file"
                                    ref={profilePicUpload}
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleUploadImage(e.target.files[0], "profilePicID")}
                                    accept=".jpg,.png,.jpeg"
                                >
                                </input>
                                <a className={`btn--${segments}`} type="file" onClick={() => profilePicUpload.current.click()} type={'file-image'} style={styles.cameraIcon}>
                                    <img src={CameraIcon} alt='No Image' width={20} />
                                    {/* <Icon type='camera' theme='filled' style={{ width: 20 }} /> */}
                                </a>
                            </div>
                        </div>
                        <div hidden={!isMobile}>
                            {notif}
                        </div>
                        <div hidden={isLoading} style={{ margin: '12px 0 0 0' }}>
                            <Typography style={{ ...styles.headerText, fontWeight: '600', ...styles.ellipsisText }}>
                                {businessName}
                            </Typography>
                            <Typography style={styles.headerText}>
                                {businessOwner}
                            </Typography>
                        </div>
                        <br />
                        <Spin size='small' hidden={!isLoading} style={{ padding: '12px 0' }} />
                        <br />
                        <br />
                    </div>
                </div>
                <Divider />
                <InfoCard segments={segments} businessData={businessData} personalData={personalData} dashboardData={dashboardData} getDashboardNow={getDashboardNow} loading={isLoading} />
            </Card>
        </div>
    )
}

const styles = {
    root: {
        margin: isMobile ? '0' : !isMobile && userType === 'SW' ? '24px 0' : '62px 0',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardRoot: {
        padding: 0,
        borderRadius: isMobile ? '0' : '20px 20px 0px 0px',
        width: isMobile ? '100%' : '645px',
    },
    cardBody: {
        padding: 0,
        width: isMobile ? '100%' : '645px',
    },
    titleDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '242px',
        width: '100%',
        borderRadius: '20px 20px 0px 0px',
        margin: '0 0 32px 0'
    },
    cameraIcon: {
        display: 'block',
        width: '40px',
        height: '40px',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logoDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        top: '45%'
    },
    cameraDiv: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '70%',
        left: '108%'
    },
    userLogoDiv: {
        display: 'block',
        width: '80px',
        height: '80px',
        borderRadius: '80px',
        backgroundColor: '#FFF',
        border: '1px solid #EEEEEE',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    userLogo: {
        display: 'block',
        width: '66px',
        height: '66px',
        borderRadius: '66px',
        backgroundColor: '#FFF'
    },
    headerText: {
        padding: '2px 0',
        fontWeight: 'normal',
        fontSize: 18,
        color: '#2B2D32',
        textAlign: 'center',
    },
    uploadBtn: {
        border: '1px solid #FFFFFF',
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#FFF',
        position: 'relative',
        left: '34%',
        bottom: '8%',
    },
    coverBG: {
        position: 'absolute',
        width: '100%',
        height: '56%',
        // backgroundImage: `url(${'https://images.unsplash.com/photo-1529234774845-b90fd07f1d23?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Y2l0eSUyMGxpZ2h0c3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: '100% 50%',
        backgroundSize: 'cover',
        backgroundPosition: '100% 50%',
        top: '0%',
        borderRadius: isMobile ? '0' : '20px 20px 0px 0px',
    },
    mobileHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#FFF',
        // margin: '0 0 32px 0',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)'
    },
    title: {
        color: '#2B2D32',
        fontSize: 18,
        fontWeight: 'bold',
        padding: '0 16px'
    },
    ellipsisText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '254px'
    }
}

export default MyProfile;